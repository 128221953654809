import React, { useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Skeleton from './Skeleton';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import './PhotoCarousel.scss';

export default function PhotoCarousel({ property }: { property: PropertyDTO }) {
  const [firstPhotoLoaded, setFirstPhotoLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const carouselRef = useRef<any>(null);


  const handleFirstPhotoLoad = () => {
    setFirstPhotoLoaded(true);
  }

  const images = property.photos && property.photos.map(photo => ({
    original: photo.url,
    thumbnail: photo.url,
    originalClass: 'object-contain md:!h-[600px] w-full',
    thumbnailClass: '',
    originalAlt: 'Property Image',
    thumbnailAlt: 'Property Thumbnail',
    loading: 'lazy',
    onLoad: handleFirstPhotoLoad
  }));

  return (
    <>
      {!property.photos && <Skeleton className='h-[600px]' />}
      {
        property.photos && property.photos.length > 0 &&
        <div className="thumbnail-gallery-holder relative">
          <Carousel
            ref={carouselRef}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            infiniteLoop={true}
            thumbWidth={150}
            dynamicHeight={true}
            showArrows={false}
            onChange={handleFirstPhotoLoad}
            onClickItem={(index) => { setPhotoIndex(index); setIsOpen(true); }}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image.original} alt={image.originalAlt} className={image.originalClass} loading={image.loading} />
              </div>
            ))}
          </Carousel>

          <div className="gallery-thumbs overflow-x-auto mt-4">
            <div className="gallery-images gap-4 w-max max-w-max ">

              {images.map((image, index) => (
                <div className="cursor-pointer" data-index={index+1} onClick={() => { carouselRef.current.moveTo(index)}}>
                  <img src={image.thumbnail} className="!h-[160px] !object-cover" />
                </div>
              ))}
            </div>
          </div>

          <button
            onClick={() => carouselRef.current.decrement(1)}
            className="absolute top-1/2 -left-4 navbutton -translate-x-full transform -translate-y-[calc(-50%_+100px)] h-[50px] w-[50px] bg-transparent shadow-none border-black border-2 -rotate-45"
          >
            &nbsp;
          </button>
          <button
            onClick={() => carouselRef.current.increment(1)}
            className="absolute top-1/2 -right-4 navbutton translate-x-full transform -translate-y-[calc(-50%_+100px)] h-[50px] w-[50px] bg-transparent shadow-none border-black border-2 rotate-[135deg] "
          >
            &nbsp;
          </button>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex].original}
              nextSrc={images[(photoIndex + 1) % images.length].original}
              prevSrc={images[(photoIndex + images.length - 1) % images.length].original}
              onCloseRequest={() => setIsOpen(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />
          )}
        </div>
      }
    </>
  );
}