import React from 'react'


export default function SelectBox({ options, disabled = false, placeholder, setValue, value }) {
  return (
    <select
      disabled={disabled}
      className="m-0 w-full border border-gray-300 rounded-md p-2 h-auto text-black"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      <option value="">{placeholder}</option>
      {options && options.map((option) => (
        <option key={option.id} value={option.id}>{option.name}</option>
      ))}
    </select>
  )
} 
