import React from "react"
import { Link } from "react-router-dom"

export default function Breadcrumbs({ property }: { property: PropertyDTO }) {
  return (
    <div className="col-md-12 prop-row">
      <p id="breadcrumbs">
        <span>
          <span>
            <a href="/">Home</a> » <Link to="/">Property Search</Link> »{" "}
            <span className="breadcrumb_last">
              {property.title}
            </span>
          </span>
        </span>
      </p>
      <Link to={"/"} className="search-return" >
        &lt; Return to Search
      </Link>
    </div>
  )
}