import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from './components/Breadcrumbs';
import TopSection from './components/TopSection';
import Bullets from './components/Bullets';
import Description from './components/Description';
import SideButtons from './components/SideButtons';
import Agents from './components/Agents';
import PhotoCarousel from './components/PhotoCarousel';
import ContactForm from './components/ContactForm';

type Props = {}




export default function PropertyPage({ }: Props) {

  const { id } = useParams();

  const [property, setProperty] = React.useState({} as PropertyDTO)

  const loadProperty = async () => {
    const response = await fetch(`/wp-json/multiply/v1/property?id=${id}`)
    const data = (await response.json()) as PropertyDTO
    setProperty(data)
  }

  useEffect(() => {
    loadProperty()
  }, [id])

  useEffect(() => {
    if (property) {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 0)
    }
  }, [property])



  return (
    property && (
      <article className="internal-container internal-container--property-single container last">
        <div className="row">
          <Breadcrumbs property={property} />

          <TopSection property={property} />

        </div>
        <PhotoCarousel property={property} />
        <div className="row prop-details">
          <div className="col-md-8">
            <h3>Key Information</h3>
            <Bullets property={property} />{" "}
            <Description property={property} />
            <p />
          </div>
          <div className="col-md-4">
            <SideButtons property={property} />
            <Agents property={property} />
            <div className="property-enquiry my-4">
              <h3 className="mb-4">Enquire About This Property</h3>
              <ContactForm property={property} />
            </div>
          </div>
        </div>
        <div
          className=""
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <iframe
            src={`https://maps.google.com/maps?q=${property.latitude},${property.longitude}&hl=es;z=14&output=embed`}
            frameBorder={0}
            style={{ border: 0 }}
            allowFullScreen
          />
        </div>
      </article>
    )
  )
}