import React from 'react'
import "./Property.scss"

export default function PropertySkeleton() {
  return (
    <div className='animate-pulse'>
      <div className="h-[350px] bg-grey-600/70"></div>
      <div className="h-[275px] bg-grey-500/70"></div>
    </div>
  )
}