import React from 'react'
import { cn } from '../../../utils/cn'

type Props = {
  placeholder: string,
  value: string,
  cssClass: string,
  setValue: (value: string) => void
}

export default function InputBox({
  placeholder,
  value,
  cssClass,
  setValue
}: Props) {
  return (
    <input type="text" className={cn('border border-gray-300 rounded-md p-2 w-full')} placeholder={placeholder} value={value}
      onChange={(e) => setValue(e.target.value)} />)
}