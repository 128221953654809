import React from 'react'

export default function Bullets({ property }: { property: PropertyDTO }) {
  if (!property.bullets || property.bullets.length === 0) {
    return null
  }
  return (
    <ul className="prop-bullets">
      {property.bullets.map((bullet, index) => (
        <li key={index}>{bullet}</li>
      ))}
    </ul>
  )
}