import React, { useMemo } from "react";



export default function SideButtons({ property }: { property: PropertyDTO }) {

  const virtualTourUrl = property.virtualTourUrl;

  const [displayVirtualTour, setDisplayVirtualTour] = React.useState(false);



  return (
    <>
      {property.documents && property.documents.map((doc, index) => (
        <a
          key={`doc-${index}`}
          target="_blank"
          href={doc.url}
          className="btn btn-primary"
        >
          View {doc.name}
        </a>
      ))}
      {" "}

      {displayVirtualTour &&
        <div id="vimeo-modal">
          <div class="modal-content">
            <div class="modal-close"
              onClick={() => setDisplayVirtualTour(false)}
            >&times;</div>

            <div class="modal-body">
              <iframe src={virtualTourUrl} width="100%" height="100%" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen autoplay></iframe>
            </div>
          </div>
        </div>
      }

      {property.virtualTourUrl && (

        <button className="btn btn-primary"
          onClick={() => setDisplayVirtualTour(true)}
        >
          View Virtual Tour
        </button>
      )}
      {" "}
      {property.agents && property.agents.length > 0 && (
        <a href={`mailto:${property.agents[0].email}`} className="btn btn-primary">
          Arrange a Viewing
        </a>
      )}
    </>
  )
}