import React, { useState, useEffect } from 'react';

export default function Agents({ property }: { property: PropertyDTO }) {

  const getAgentLocalPicture = async (agent: AgentDTO) => {
    if (agent.photo) {
      return agent.photo;
    } else {
      const response = await fetch(`/wp-json/wp/v2/person?search=${agent.name}&_embed=wp:featuredmedia&_fields=_links.wp:featuredmedia`);
      const data = await response.json();

      if (data && data.length > 0) {
        const media = data[0]._embedded['wp:featuredmedia'][0];
        return media.media_details.sizes.medium.source_url;
      } else {
        return '';
      }
    }
  };

  return (
    <div className="property-agents-single">
      <h3 className="agent-title">Agent Details</h3>
      <div className="row">
        {property.agents && property.agents.map((agent, index) => (
          <AgentDetail key={`agent-${index}`} agent={agent} getAgentLocalPicture={getAgentLocalPicture} />
        ))}
      </div>
    </div>
  );
}

function AgentDetail({ agent, getAgentLocalPicture }: { agent: AgentDTO, getAgentLocalPicture: (agent: AgentDTO) => Promise<string> }) {
  const [agentPhoto, setAgentPhoto] = useState<string>('');

  useEffect(() => {
    const fetchPhoto = async () => {
      const photo = await getAgentLocalPicture(agent);
      setAgentPhoto(photo);
    };

    fetchPhoto();
  }, [agent, getAgentLocalPicture]);

  return (
    <div className="author-details col-lg-6">
      <img
        width={429}
        height={570}
        src={agentPhoto}
        className="attachment-post-thumbnail size-post-thumbnail !object-contain wp-post-image"
        alt={agent.name}
      />
      <h3>{agent.name}</h3>
      <a href={`tel:${agent.phone}`}>T: {agent.phone}</a>
      <a href={`tel:${agent.mobile}`}>M: {agent.mobile}</a>
      <a className="red agent-email" href={`mailto:${agent.email}`}>
        {agent.email}
      </a>
    </div>
  );
}
