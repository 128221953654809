import React from 'react'
import StarIcon from './StarIcon'
import "./Property.scss"
import { Link } from 'react-router-dom'

type Props = {
  property: PropertyListingDTO
}

function StatusBadge({ status }: { status: string }) {
  return (
    <span className="block absolute top-0 right-0 bg-red-500 text-white p-2 w-[100px] text-center font-normal text-[14px]">{status}</span>
  )
}
function FeaturedBadge() {
  return (
    <span className="block absolute top-0 left-0 bg-darkblue-500 text-white p-2 w-[120px] text-center font-normal text-[14px]">
      <StarIcon />
      <span className="pl-2">Featured</span>
    </span>
  )
}

export default function Property({ property }: Props) {
  return (
    <div>
      <div className="property-inner relative bg-grey-500 h-full ">
        <Link to={property.listingUrl} className="pb-[50px]">
          <StatusBadge status={property.status} />
          {property.isFeatured && <FeaturedBadge />}



          <img src={property.photo} alt={property.title} className="!h-[275px] w-full object-cover" />
          <div className="p-4 property__contenttext">
            <h2 className="property__title">
              {property.title}
            </h2>
            <div className="">
              {property.address} 
            </div>
            <div className="mt-4">
              <div className="flex justify-between">
                <div className="">
                  {property.isPOA ? "P.O.A." : property.price}
                </div>
                <div className="">
                  {property.area}
                </div>
              </div>
              <div className="property__bullets" dangerouslySetInnerHTML={{ __html: property.description }} />
            </div>
          </div>

          <div className="agent-section absolute inset-x-0 bottom-0 text-center bg-red-500 p-2 text-white">
            Find out more
          </div>
        </Link>
      </div>
    </div>
  )
}