import React, { useEffect, useMemo } from 'react';
import { useSearchStoreEasy } from '../../utils/search.store';
import GoogleMapReact from 'google-map-react-concurrent';
import { Link } from 'react-router-dom';

type Props = {};

const Pin = React.memo(({ property, selectedProperty, setSelectedProperty, setCurrentCenter }) => {

  return (
    <div className='relative' style={{ top: "-33px", left: "-16.5px" }}>
      <img style={{ maxWidth: 'none', height: '33px', width: '33px' }} src={'/wp-content/themes/graham/assets/images/pin.png'} alt=''
        onClick={() => {
          setSelectedProperty(property);
          setCurrentCenter({ lat: property.latitude, lng: property.longitude });
        }}
      />
      {selectedProperty === property && (
        <div className="property-map-info w-[600px] bg-white p-4 z-[1000] relative translate-x-[50px] -translate-y-1/2">
          <button className="absolute -top-1 bg-transparent right-0 p-2 font-bold !text-black !text-[40px]" onClick={() => setSelectedProperty(null)}>&times;</button>
          <div className="property-map-info__content">
            <h3>{property.address}</h3>
            <div className="property-map-info__saletype">{property.status}</div>
            <p>{property.address}</p>
            <p>{property.price}</p>
            <p>
              <Link to={property.listingUrl} >
                <strong>View Property Page</strong>
              </Link>
            </p>
          </div>
          <div className="property-map-info__image">
            <img src={property.photo} alt="" />
          </div>
        </div>
      )}

    </div>
  );
});

export default function PropertiesMap({ }: Props) {

  const { gmapsKey } = useSearchStoreEasy();

  const defaultProps = useMemo(() => ({
    center: {
      lat: 55.0109947,
      lng: -5.0069887
    },
    zoom: 6
  }), []);

  const [currentCenter, setCurrentCenter] = React.useState(defaultProps.center);
  const [mapsProperties, setMapsProperties] = React.useState([]);
  const [selectedProperty, setSelectedProperty] = React.useState(null);

  const fetchProperties = async () => {
    const response = await fetch(`/wp-json/multiply/v1/maps-properties`);
    const data = await response.json();
    setMapsProperties(data);
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  const pins = useMemo(() => (
    mapsProperties.map((property: any, index: number) => (
      <Pin
        key={index}
        property={property}
        selectedProperty={selectedProperty}
        setSelectedProperty={setSelectedProperty}
        setCurrentCenter={setCurrentCenter}
        lat={property.latitude}
        lng={property.longitude}
      />
    ))
  ), [mapsProperties, selectedProperty]);

  return (
    <div style={{ height: '600px', width: '100%' }} className=''>
      <GoogleMapReact
        bootstrapURLKeys={{ key: gmapsKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        center={currentCenter}
      >
        {pins}
      </GoogleMapReact>
    </div>
  );
}
