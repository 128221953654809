import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import PropertiesList from '../PropertiesList/PropertiesList';
import PropertyPage from '../PropertyPage/PropertyPage';
import { useSearchStoreEasy } from '../../utils/search.store';

const PropertiesRouter = ({ masonry, mapsKey }: {
  masonry: string;
  mapsKey: string;
}) => {
  // Dynamically set the basename to match the current WordPress page URL
  let basename = window.location.pathname;

  // if the basename contains /property/<number> then we need to remove the /property/<number> part
  if (basename.includes('/property/')) {
    const split = basename.split('/');
    basename = split.slice(0, split.indexOf('property')).join('/');
  }

  const [masonryProps, setMasonryProps] = React.useState<MasonryProps[]>([]);

  React.useEffect(() => {
    if (masonry) {
      const parsedMasonry = JSON.parse(masonry);
      setMasonryProps(parsedMasonry);
    }
  }, [masonry]);

  const { resetSearch, setGmapsKey } = useSearchStoreEasy()

  useEffect(() => {
    resetSearch()
  }, [])

  useEffect(() => {
    if (mapsKey) {
      setGmapsKey(mapsKey)
    }
  }, [mapsKey])


  return (
    <>
      <Router basename={basename}>
        <Routes>
          <Route path="/" element={<PropertiesList masonryProps={masonryProps} />} />
          <Route path="/property/:id" element={<PropertyPage />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </Router>
    </>
  );
};

export default PropertiesRouter;
