import React, { useEffect, useState, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom';
import InputBox from './forms/InputBox'
import SearchButton from './forms/SearchButton'
import { useSearchStoreEasy } from '../../utils/search.store'
import { debounce } from 'lodash';

type Props = {}

function SelectBox({ options, disabled = false, placeholder, setValue, value }) {
  return (
    <select
      disabled={disabled}
      className="m-0 w-full border border-gray-300 rounded-md p-2 h-auto text-black"
      value={value}
      onChange={(e) => setValue(e.target.value)}
    >
      <option value="">{placeholder}</option>
      {options && options.map((option) => (
        <option key={option.id} value={option.id}>{option.name}</option>
      ))}
    </select>
  )
}

export default function PropertySearch({ }: Props) {
  const [searchParams] = useSearchParams();

  const { search, selectedListingType, selectedPropertyType, selectedLocation,
    selectedMinPrice, selectedMaxPrice, selectedOtherPropertyType, selectedKeywords, displayedProperties,
    numberOfResults, selectedOwner, page, order, initialLoadFinished,
    
    setPage, setMaxPage, setSearch, setSelectedListingType, setSelectedPropertyType, setSelectedLocation, setOrder,
    setSelectedMinPrice, setSelectedMaxPrice, setSelectedOtherPropertyType, setInitialLoadFinished,
    setSelectedKeywords, setDisplayedProperties, setNumberOfResults } = useSearchStoreEasy()

  const [showAll, setShowAll] = useState(false)
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [debouncedKeywords, setDebouncedKeywords] = useState(selectedKeywords);
  const [lastUrl, setLastUrl] = useState('');

  const dataSale = [50000, 100000, 250000, 500000, 1000000]
  const dataRent = [1000, 2500, 5000, 10000, 20000, 50000]

  function getOptions(data) {
    return data.map((value) => ({ id: value, name: "£" + value.toLocaleString() }))
  }

  const [propertyTypes, setPropertyTypes] = useState([])
  const [locations, setLocations] = useState([])

  const loadProperty = async (slug, setProperty) => {
    const response = await fetch(`/wp-json/multiply/v1/${slug}`)
    const data = await response.json()
    setProperty(data)
  }

  useEffect(() => {
    if(propertyTypes.length > 0 && locations.length > 0) {
      // Parse URL params and preload values
      const propertyLocation = searchParams.get('propertyLocation');
      const propertySale = searchParams.get('propertySale');
      const propertyType = searchParams.get('propertyType');
      const propertyRegion = searchParams.get('propertyRegion');
  

      if (propertyLocation && propertyLocation !== "all") {setSearch(propertyLocation); setDebouncedSearch(propertyLocation);} ;
      if (propertySale && propertySale !== "all") setSelectedListingType(propertySale == "for_sale" ? "buy" : "rent");
      if (propertySale && propertyType !== "all") {
  
        const valueFound = propertyTypes.find((property) => property.name == propertyType);
        if (valueFound) setSelectedPropertyType(valueFound.id);
  
      } 

      if(propertyRegion && propertyRegion !== "all") {
        setSelectedLocation(propertyRegion);
      }

    }
  }, [propertyTypes,locations, searchParams]);


  const loadProperties = async () => { 
    await loadProperty('property-types', setPropertyTypes)
    await loadProperty('locations', setLocations)
  }

  useEffect(() => {
    // Reset results
    setDisplayedProperties([])
    setNumberOfResults(0)
    
    setTimeout(() => {
      setInitialLoadFinished(false)
      loadProperties()
    })
  }, [])

  const getFilteredProperties = useCallback(
    debounce(async (search = '', selectedKeywords = '') => {
      const url =
        `/wp-json/multiply/v1/properties?search=${search}&listingType=${selectedListingType}&propertyType=${selectedPropertyType}&location=${selectedLocation}&minPrice=${selectedMinPrice}&maxPrice=${selectedMaxPrice}&otherPropertyType=${selectedOtherPropertyType}&keywords=${selectedKeywords}&owner=${selectedOwner}&page=${page}&order=${order}`

      // Check if the current URL matches the last URL
      if (url === lastUrl) return;

      setLastUrl(url); // Update last URL called

      const response = await fetch(url)
      const data = await response.json()

      if (page > 1) {
        setDisplayedProperties([...displayedProperties, ...data.properties])
      } else {
        setDisplayedProperties(data.properties)
      }
      setNumberOfResults(data.total_properties)
      setMaxPage(data.total_pages)
      setPage(data.current_page)

      if(!initialLoadFinished) {
        setInitialLoadFinished(true)
      }

    }, 500),
    [selectedListingType, selectedPropertyType, selectedLocation, selectedMinPrice, selectedMaxPrice, selectedOtherPropertyType, selectedOwner, page, order, lastUrl]
  )

  useEffect(() => {
    setPage(1)
    getFilteredProperties(debouncedSearch, debouncedKeywords)
  }, [debouncedSearch, debouncedKeywords, selectedListingType, selectedPropertyType, selectedLocation, selectedMinPrice, selectedMaxPrice, selectedOtherPropertyType, selectedKeywords, selectedOwner, order])

  useEffect(() => {
    getFilteredProperties(debouncedSearch, debouncedKeywords)
  }, [page])

  useEffect(() => {
    const handleDebouncedSearch = debounce((value) => {
      setDebouncedSearch(value)
    }, 500)

    handleDebouncedSearch(search)

    const handleDebouncedKeywords = debounce((value) => {
      setDebouncedKeywords(value)
    }, 500)

    handleDebouncedKeywords(selectedKeywords) 

    return () => {
      handleDebouncedSearch.cancel()
      handleDebouncedKeywords.cancel()
    }
  }, [search, selectedKeywords])

  return (
    <div className="bg-lightgrey-500">
      <div className="container mx-auto p-4 ">
        <h2 className="text-center mb-4">Property Search</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 ">
          <div className="md:col-span-3 flex">
            <InputBox placeholder="Search by Building Name, Street, Town or Postcode" value={search} cssClass="" setValue={setSearch} />
            <SearchButton onClick={() => { }} />
          </div>
          <SelectBox placeholder="Listing Type" options={[{ id: 'buy', name: 'Buy' }, { id: 'rent', name: 'Rent' }]} value={selectedListingType} setValue={setSelectedListingType} />
          <SelectBox placeholder="Property Type" options={propertyTypes} value={selectedPropertyType} setValue={setSelectedPropertyType} />
          <SelectBox placeholder="Location" options={locations.map((location) => ({ id: location, name: location }))} value={selectedLocation} setValue={setSelectedLocation} />

          {
            showAll && (
              <>
                <SelectBox
                  placeholder="Min Price"
                  options={getOptions(selectedListingType === 'buy' ? dataSale : dataRent)}
                  disabled={selectedListingType === ''}
                  value={selectedMinPrice}
                  setValue={setSelectedMinPrice} />

                <SelectBox
                  placeholder="Max Price"
                  disabled={selectedListingType === ''}
                  options={getOptions(selectedListingType === 'buy' ? dataSale : dataRent)}
                  value={selectedMaxPrice}
                  setValue={setSelectedMaxPrice} />

                <SelectBox
                  placeholder="Price"
                  options={[
                    { id: 'recent', name: 'Most recent first' },
                    { id: 'desc', name: 'High to Low' },
                    { id: 'asc', name: 'Low to High' },
                  ]}
                  value={order}
                  setValue={setOrder} />
              </>
            )
          }
        </div>
        {selectedKeywords}

        <div className="text-right mt-4">
          <button onClick={() => setShowAll(!showAll)} className="bg-white !text-red-500 !font-normal">Show {showAll ? 'Less' : 'More'} Filters</button>
        </div>
      </div>
    </div>
  )
}
