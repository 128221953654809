import React, { useRef, useEffect, useState, useCallback } from 'react';
import PropertySearch from '../shared/PropertySearch';
import { useSearchStoreEasy } from '../../utils/search.store';
import Property from './Property';
import Masonry from '../Masonry/Masonry';
import PropertiesMap from '../PropertiesMap/PropertiesMap';
import PropertySkeleton from './PropertySkeleton';

type Props = {
  masonryProps: MasonryProps[];
};

export default function PropertiesList({ masonryProps }: Props) {
  const {
    search,
    selectedListingType,
    selectedPropertyType,
    selectedLocation,
    selectedMinPrice,
    selectedMaxPrice,
    selectedOtherPropertyType,
    selectedOwner,
    selectedKeywords,
    displayedProperties,
    numberOfResults,
    resetSearch,
    setPage,
    page,
    maxPage,
    initialLoadFinished,
  } = useSearchStoreEasy();

  const [noSearchYet, setNoSearchYet] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const sentinelRef = useRef<HTMLDivElement | null>(null);

  const searchEmpty =
    search ||
    selectedListingType ||
    selectedPropertyType ||
    selectedLocation ||
    selectedMinPrice ||
    selectedMaxPrice ||
    selectedOtherPropertyType ||
    selectedKeywords ||
    selectedOwner;

  // Reset page and detect initial search
  useEffect(() => {
    if (noSearchYet && searchEmpty) {
      setNoSearchYet(false);
    }
    setPage(1);
  }, [
    search,
    selectedListingType,
    selectedPropertyType,
    selectedLocation,
    selectedMinPrice,
    selectedMaxPrice,
    selectedOtherPropertyType,
    selectedKeywords,
    selectedOwner,
    setPage,
  ]);

  // Debounced load more function
  const loadMore = useCallback(() => {
    if (+page < maxPage && !isLoadingMore && initialLoadFinished) {
      console.log(`Triggering load: page ${+page + 1} of ${maxPage}`);
      setIsLoadingMore(true);
      setTimeout(() => {
        setPage(+page + 1);
      }, 300); // 300ms debounce to prevent rapid triggers
    } else {
      console.log('Load more blocked:', { page, maxPage, isLoadingMore, initialLoadFinished });
    }
  }, [page, maxPage, isLoadingMore, initialLoadFinished, setPage]);

  // Set up Intersection Observer
  useEffect(() => {
    if (!initialLoadFinished || !sentinelRef.current) return;

    console.log('Setting up observer:', { page, maxPage, isLoadingMore });

    observerRef.current = new IntersectionObserver(
      (entries) => {
        console.log('Observer triggered:', entries[0].isIntersecting);
        if (entries[0].isIntersecting && !isLoadingMore) {
          loadMore();
        }
      },
      {
        root: null,
        rootMargin: '400px', // Increased to trigger earlier and give buffer
        threshold: 0.1, // Trigger when 10% of sentinel is visible
      }
    );

    const sentinel = sentinelRef.current;
    if (sentinel) {
      observerRef.current.observe(sentinel);
      console.log('Observing sentinel');
    }

    return () => {
      if (observerRef.current && sentinel) {
        observerRef.current.unobserve(sentinel);
        observerRef.current.disconnect();
        console.log('Cleaned up observer');
      }
    };
  }, [initialLoadFinished, isLoadingMore, loadMore]); // Note: loadMore is now a dependency

  // Reset loading state when new properties are loaded
  useEffect(() => {
    if (isLoadingMore && initialLoadFinished) {
      console.log('Resetting isLoadingMore');
      setIsLoadingMore(false);
    }
  }, [displayedProperties]);

  return (
    <div className="font-bold mb-8">
      <PropertySearch />
      {selectedPropertyType === '18' && <PropertiesMap />}

      {noSearchYet && <Masonry masonryProps={masonryProps} />}

      <div className="bg-white container mx-auto p-4">
        <h3 className="propCount mb-4">
          {initialLoadFinished ? (
            <>
              Your search returned{' '}
              <span className="text-red-500">{numberOfResults}</span> results
            </>
          ) : (
            <>Loading your results...</>
          )}
        </h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {!initialLoadFinished && (
            <>
              <PropertySkeleton />
              <PropertySkeleton />
              <PropertySkeleton />
              <PropertySkeleton />
              <PropertySkeleton />
              <PropertySkeleton />
            </>
          )}
          {displayedProperties.map((property, index) => (
            <Property key={property.id || index} property={property} />
          ))}
        </div>

        {/* Loading indicator */}
        {isLoadingMore && (
          <div className="flex justify-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
          </div>
        )}

        {/* Sentinel for infinite scroll */}
        {initialLoadFinished && +page < maxPage && (
          <div ref={sentinelRef} className="h-10" />
        )}
      </div>
    </div>
  );
}