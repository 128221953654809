import React from 'react'
import { Link } from 'react-router-dom'
import { useSearchStoreEasy } from '../../utils/search.store'


type Props = {
  masonryProps: MasonryProps[]
}

export default function Masonry({
  masonryProps
}: Props) {

  const { setSelectedPropertyType, setSelectedOwner } = useSearchStoreEasy()

  const handleClicked = (property_type: string) => {
    if (property_type === 'cityProperty') {
      setSelectedOwner("CP");
    } else {
      setSelectedPropertyType(property_type)
    }
  }

  return (
    <div className="container my-4">
      <div className="gs_masonry">
        {
          masonryProps.map((masonryProp, index) => (
            <button className="gs_masonry__item"
              onClick={() => handleClicked(masonryProp.property_type)}
              key={index}>
              <div className="gs_masonry__image" style={{ background: `url(${masonryProp.image}) no-repeat center center / cover` }}></div>
              <div className="gs_masonry__overlay"></div>
              <h2>{masonryProp.title}</h2>
            </button>)
          )
        }

      </div>
    </div>
  )
}