import React from 'react'
import StarIcon from '../PropertiesList/StarIcon'
import "../PropertiesList/Property.scss"
import { Link } from 'react-router-dom'

type Props = {
  property: PropertyListingDTO
}

function StatusBadge({ status }: { status: string }) {
  return (
    <span className="block absolute top-0 right-0 bg-red-500 text-white p-2 w-[100px] text-center font-normal text-[14px]">{status}</span>
  )
}
function FeaturedBadge() {
  return (
    <span className="block absolute top-0 left-0 bg-darkblue-500 text-white p-2 w-[120px] text-center font-normal text-[14px]">
      <StarIcon />
      <span className="pl-2">Featured</span>
    </span>
  )
}

export default function SoldProperty({ property }: Props) {
  return (
    <div>
      <div className="property-inner relative bg-grey-500 h-full ">
        <div className="pb-[50px]">
          <StatusBadge status={"Sold"} />

          <img src={property.photo} alt={property.title} className="!h-[275px] w-full object-cover" />
          <div className="p-4 property__contenttext">
            <h2 className="property__title">
              {property.title}
            </h2>
            <div className="">
              {property.address}
            </div>
            <div className="mt-4">
              <div className="flex justify-between">
                <div className="">
                  {property.isPOA ? "P.O.A." : property.price}
                </div>
                <div className="">
                  {property.area}
                </div>
              </div>
              <div className="property__bullets" dangerouslySetInnerHTML={{ __html: property.description }} />
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}