import React from "react"

export default function TopSection({ property }: { property: PropertyDTO }) {
  return (
    <div className="col-md-12 prop-titles">
      <div className="top-section">
        <h1
          className="line-clamp-2"
          title={property.title}
        >
          {property.title}
        </h1>
        <div className="sale-type">{property.status}</div>{" "}
      </div>
      <address className="fs-5 mb-3">
        {property.address}
      </address>
      <ul className="property-details-list">
        <li> {property.price}</li> <li>{property.area}</li>
      </ul>
    </div>
  )
}