import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface SearchState {
  search: string
  selectedListingType: string
  selectedPropertyType: string
  selectedLocation: string
  selectedMinPrice: number
  selectedMaxPrice: number
  selectedOtherPropertyType: string
  selectedKeywords: string
  selectedOwner: string
  displayedProperties: PropertyListingDTO[]
  numberOfResults: number
  page: number
  order: string
  gmapsKey: string
  initialLoadFinished: boolean
  maxPage: number

  setSearch: (search: string) => void
  setMaxPage: (maxPage: number) => void
  setSelectedListingType: (selectedListingType: string) => void
  setSelectedPropertyType: (selectedPropertyType: string) => void
  setSelectedLocation: (selectedLocation: string) => void
  setSelectedMinPrice: (selectedMinPrice: number) => void
  setSelectedMaxPrice: (selectedMaxPrice: number) => void
  setSelectedOtherPropertyType: (selectedOtherPropertyType: string) => void
  setSelectedKeywords: (selectedKeywords: string) => void
  setSelectedOwner: (selectedOwner: string) => void
  setDisplayedProperties: (displayedProperties: PropertyListingDTO[]) => void
  setNumberOfResults: (numberOfResults: number) => void
  resetSearch: () => void
  setOrder: (order: string) => void
  setPage: (page: number) => void
  setGmapsKey: (gmapsKey: string) => void
  setInitialLoadFinished: (initialLoadFinished: boolean) => void

}

const useSearchStore = create<SearchState>()(
  persist(
    (set) => ({
      search: '',
      gmapsKey: '',
      selectedListingType: '',
      selectedPropertyType: '',
      selectedLocation: '',
      selectedMinPrice: 0,
      selectedMaxPrice: 0,
      selectedOtherPropertyType: '',
      selectedKeywords: '',
      displayedProperties: [],
      numberOfResults: 0,
      selectedOwner: '',
      order: 'recent',
      page: 1,
      maxPage: 1,
      initialLoadFinished: false,

      setMaxPage: (maxPage) => set({ maxPage }),
      setSearch: (search) => set({ search }),
      setSelectedListingType: (selectedListingType) => set({ selectedListingType }),
      setSelectedPropertyType: (selectedPropertyType) => {
        set({page: 1})
        return set({ selectedPropertyType })
      } ,

      setOrder: (order) => set({ order }),

      setSelectedOwner: (selectedOwner) => set({ selectedOwner }),
      setSelectedLocation: (selectedLocation) => set({ selectedLocation }),
      setSelectedMinPrice: (selectedMinPrice) => set({ selectedMinPrice }),
      setSelectedMaxPrice: (selectedMaxPrice) => set({ selectedMaxPrice }),
      setSelectedOtherPropertyType: (selectedOtherPropertyType) => set({ selectedOtherPropertyType }),
      setSelectedKeywords: (selectedKeywords) => set({ selectedKeywords }),
      setDisplayedProperties: (displayedProperties) => set({ displayedProperties }),
      setNumberOfResults: (numberOfResults) => set({ numberOfResults }),
      resetSearch: () => set({
        search: '',
        selectedListingType: '',
        selectedPropertyType: '',
        selectedLocation: '',
        selectedMinPrice: 0,
        selectedMaxPrice: 0,
        selectedOtherPropertyType: '',
        selectedKeywords: '',
        displayedProperties: [],
        numberOfResults: 0,
        selectedOwner: '',
      }),
      setPage: (page) => set({ page }),
      setGmapsKey: (gmapsKey) => set({ gmapsKey }),
      setInitialLoadFinished: (initialLoadFinished) => set({ initialLoadFinished }),
      
    }), 
    {
      name: 'search-storage-v1',
    },
  ),
)

const useSearchStoreEasy = new Proxy(useSearchStore, {
  get: function(target, prop) {
    if (typeof prop === 'string' && target.getState().hasOwnProperty(prop)) {
      return target.getState()[prop];
    }
    if (typeof target[prop] === 'function') {
      return target[prop];
    }
    return undefined;
  }
});

export { useSearchStore, useSearchStoreEasy }