import React, { useEffect, useState } from 'react'
import PropertySearch from '../shared/PropertySearch'
import { useSearchStoreEasy } from '../../utils/search.store'
import SoldProperty from './SoldProperty'
import PropertyPage from '../PropertyPage/PropertyPage'
import Masonry from '../Masonry/Masonry'
import PropertiesMap from '../PropertiesMap/PropertiesMap'
import SelectBox from '../SelectBox'


export default function PropertiesList() {

  const [propertyTypes, setPropertyTypes] = useState([])

  const { selectedPropertyType, displayedProperties,
    order, setOrder,
    setSelectedPropertyType, setDisplayedProperties } = useSearchStoreEasy()

  const getData = async () => {

    const url = `/wp-json/multiply/v1/soldproperties?property_type=${selectedPropertyType}&order=${order}`

    const response = await fetch(url)
    const data = await response.json()

    setDisplayedProperties(data.properties)
  }

  const loadProperty = async (slug, setProperty) => {
    const response = await fetch(`/wp-json/multiply/v1/${slug}`)
    const data = await response.json()
    setProperty(data)
  }

  const loadProperties = async () => {
    await loadProperty('property-types', setPropertyTypes)
  }

  useEffect(() => {
    loadProperties()
  }, [])

  useEffect(() => {
    getData()
  }, [selectedPropertyType, order])



  return (
    <div className="font-bold">

      <div className="bg-[#EDEBEC] text-center px-4 py-12 mb-8 ">
        <h1>Recently Sold Properties</h1>
        <div className="max-w-[700px] mx-auto flex gap-4">
          <SelectBox placeholder="Property Type" options={propertyTypes} value={selectedPropertyType} setValue={setSelectedPropertyType} />
          <SelectBox
            placeholder="Price"
            options={[
              { id: 'recent', name: 'Most recent first' },
              { id: 'desc', name: 'High to Low' },
              { id: 'asc', name: 'Low to High' },
            ]}
            value={order}
            setValue={setOrder} />
        </div>
      </div>

      {
        displayedProperties &&
        <div className="bg-white container mx-auto p-4 ">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* {JSON.stringify(displayedProperties)} */}
            {displayedProperties.map((property) => (
              <SoldProperty property={property} />
            ))}
          </div>
        </div>
      }

    </div >
  )
}  