import React from 'react'

type Props = {}

export default function Description({ property }: { property: PropertyDTO }) {
  return (
    <>
      <h3>Property Description</h3>
      <p dangerouslySetInnerHTML={{ __html: property.description }} />
    </>
  )
}