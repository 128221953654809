import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "./ContactForm.scss";

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .required('Phone number is required'),
  message: Yup.string()
    .required('Message is required'),
  signUpForEmail: Yup.boolean(),
  consent: Yup.boolean()
    .oneOf([true], 'Consent is required to receive communications from Graham + Sibbald'),
});

const ContactForm = ({ property }: { property: PropertyDTO }) => {

  if (!property || !property.agents) {
    return null;
  }
  const [markup, setMarkup] = React.useState('');
  const url = `/wp-json/multiply/v1/get-form-markup?title=Property+Enquiry`;

  useEffect(() => {
    const fetchMarkup = async () => {
      const response = await fetch(url);
      const data = await response.json();

      const fullMarkup = data.markup.replace(url, '')
        .replace("</form>", `
          <input type="hidden" name="property_id" value="${property.id}">
        </form>`
        )


      setMarkup(fullMarkup);

      // We need to do wpcf7.init(document.querySelector('.wpcf7-form'))
      // to initialise the contact form 7 form

      setTimeout(() => {
        // @ts-ignore
        if (typeof wpcf7 !== 'undefined') {
          // @ts-ignore
          wpcf7.init(document.querySelector('.wpcf7-form'));
        }
      }, 200);
    };

    fetchMarkup();
  }, [property]);

  return (
    <div className="property-contact-form">
      <div dangerouslySetInnerHTML={{ __html: markup }} />
    </div>
    // <Formik
    //   initialValues={{
    //     name: '',
    //     email: '',
    //     phoneNumber: '',
    //     message: '',
    //     signUpForEmail: false,
    //     consent: false,
    //   }}

    //   validationSchema={SignupSchema}
    //   onSubmit={values => {
    //     // handle form submission
    //     console.log(values);
    //   }}
    // >
    //   {({ errors, touched }) => (
    //     <Form>
    //       <div className="property-field">
    //         <label htmlFor="name">Name (Your Name *)</label>
    //         <Field name="name" />
    //         <ErrorMessage name="name" component="div" className='property-field-error' />
    //       </div>
    //       <div className="property-field">
    //         <label htmlFor="email">Email *</label>
    //         <Field name="email" type="email" />
    //         <ErrorMessage name="email" component="div" className='property-field-error' />
    //       </div>
    //       <div className="property-field">
    //         <label htmlFor="phoneNumber">Phone Number *</label>
    //         <Field name="phoneNumber" />
    //         <ErrorMessage name="phoneNumber" component="div" className='property-field-error' />
    //       </div>
    //       <div className="property-field">
    //         <label htmlFor="message">Message *</label>
    //         <Field name="message" as="textarea" />
    //         <ErrorMessage name="message" component="div" className='property-field-error' />
    //       </div>
    //       <div className="property-field">
    //         <label className=''>
    //           <Field type="checkbox" name="signUpForEmail" className="float-left" />
    //           Tick here if you would like to sign up to Graham + Sibbald's email circulation list
    //         </label>
    //       </div>
    //       <div className="property-field">
    //         <label>
    //           <Field type="checkbox" name="consent" className="float-left" />
    //           Consent to receive communications from Graham + Sibbald
    //         </label>
    //         <ErrorMessage name="consent" component="div" className='property-field-error' />
    //       </div>
    //       <button type="submit">Submit</button>
    //     </Form>
    //   )}
    // </Formik>
  );
};

export default ContactForm;
