import React from 'react'
import { cn } from '../../../utils/cn'

type Props = {
  className?: string
}

export default function Skeleton({
  className
}: Props) {
  return (
    <div className={cn(`animate-pulse bg-grey-500 min-h-[80px w-full] rounded`, className)} />
  )
}