import { createRoot } from 'react-dom/client';
import "./index.scss";
import React from 'react';
import ReactDOM from "react-dom";
import PropertiesList from "./components/PropertiesList/PropertiesList";
import PropertiesRouter from "./components/PropertiesRouter/PropertiesRouter";
import Masonry from "./components/Masonry/Masonry";
import SoldProperties from "./components/SoldProperties/SoldProperties";


// When document loads, load React app on .mpy-react. Don't forget to import dependencies and use createRoot
document.addEventListener("DOMContentLoaded", () => {
  const domNodes = document.querySelectorAll(".mpy-react.propertieslist")

  domNodes.forEach((node) => {
    const dataProperties = node.getAttribute("data-properties");
    const dataGmapsKey = node.getAttribute("data-gmapskey");
    // ReactDOM.render(<PropertiesList masonryProps={JSON.parse(dataProperties!)} />, node);
    createRoot(node).render(<PropertiesRouter mapsKey={dataGmapsKey!} masonry={dataProperties!} />);
  });

  const domSoldProperties = document.querySelectorAll(".mpy-react.soldpropertieslist")

  domSoldProperties.forEach((node) => {
    const dataGmapsKey = node.getAttribute("data-gmapskey");
    // ReactDOM.render(<PropertiesList masonryProps={JSON.parse(dataProperties!)} />, node);
    createRoot(node).render(<SoldProperties />);
  });



});
